import moment from 'moment';
import { addBusinessDays } from '@/modules/daiwa-house-modular-europe/ProductionPlanning/dhme-production-planning.utils';
import { queryTasksV2 } from '@/services/api/v2/tasks.v2.api';

export async function generateManualPlanning(
  locations,
  startHour,
  workHours,
  startDate,
  generationMethod,
  modules,
  locationTypeFilter,
  assemblyTask,
  assemblyTasks,
  vacationDays,
  alignedPhase,
  locationPlanType,
  endDate
) {
  let tasks = [];

  let alignedTasks = [];
  if (alignedPhase) {
    const { tasks } = await queryTasksV2(alignedPhase.license, [
      {
        column: 'type',
        operator: '=',
        values: ['dhme-module-assembly'],
      },
      {
        column: 'project',
        operator: '=',
        values: [alignedPhase.task_project.id],
      },
    ]);
    alignedTasks = tasks;
  }

  let currentLaneId = locations[0].assembly_lane;
  let generationMethodDayDelta = 0;
  locations.forEach((l) => {
    if (alignedPhase) {
      let locationEndDates = alignedTasks
        .filter((t) => t.task_type.custom_3 === l.id)
        .map((t) => moment(t.planned_end));
      if (locationEndDates.length > 0) {
        l.current = moment.max(locationEndDates).clone();
      } else {
        l.current = moment(alignedPhase.planned_start).set({
          hour: startHour,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
      }
      l.dayEnd = l.current.clone().set({
        hour: startHour + workHours,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      if (l.current.isSame(l.dayEnd)) {
        l.current = addBusinessDays(l.dayEnd, 1, vacationDays).clone().set({
          hour: startHour,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        l.dayEnd = l.current.clone().set({
          hour: startHour + workHours,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
      }
    } else {
      l.dayEnd = moment(startDate)
        .clone()
        .set({
          hour: startHour + workHours,
          minute: 0,
          second: 0,
          millisecond: 0,
        });

      l.current = moment(startDate).clone().set({
        hour: startHour,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
    }

    switch (generationMethod) {
      case 'pendulum':
        generationMethodDayDelta =
          (l.assembly_lane !== currentLaneId) ^
          (generationMethodDayDelta === 0);
        break;
      case 'waterfall':
        if (l.assembly_lane !== currentLaneId) {
          generationMethodDayDelta++;
        }
        break;
    }

    l.dayEnd = addBusinessDays(
      l.dayEnd,
      generationMethodDayDelta,
      vacationDays
    ).clone();
    l.current = addBusinessDays(
      l.current,
      generationMethodDayDelta,
      vacationDays
    ).clone();

    currentLaneId = l.assembly_lane;
  });

  modules.forEach((m, index) => {
    let modulePlanned = false;
    let moduleWorkload = parseInt(m.assembly_workload);
    let moduleTypeLocations = Object.keys(locationTypeFilter).filter((key) =>
      locationTypeFilter[key].includes(m.module_type)
    );

    let task = {
      title: `Assembly module '${m.module_id}'`,
      project: assemblyTask.task_project.id,
      parent: assemblyTask.id,
      sbscode: m.module_id,
      options: {
        type: 'dhme-module-assembly',
        custom_1: m.module_id,
      },
    };

    let plannedTask = assemblyTasks.find(
      (t) => t?.sbscode?.code === task?.sbscode
    );

    // set id to update
    if (plannedTask) {
      task.id = plannedTask.id;
    }

    while (!modulePlanned) {
      let sortedLocations = locations
        .filter((l) =>
          moduleTypeLocations.length > 0
            ? moduleTypeLocations.includes(l.id)
            : true
        )
        .sort((a, b) => moment(a.current).diff(moment(b.current)));

      let location = locations.find((l) => l.id === sortedLocations[0].id);

      task.options.custom_2 = location.assembly_hall;
      if (locationPlanType === 'automated') {
        task.options.custom_3 = location.id;
        task.options.custom_11 = location.id;
      }

      let startDate = moment(location.current).clone().add(index, 'seconds');
      let endDate = startDate.clone().add(moduleWorkload * 60, 'minutes');

      while (endDate.isAfter(location.dayEnd)) {
        let diff = endDate.diff(location.dayEnd, 'minutes');

        endDate = addBusinessDays(endDate, 1, vacationDays)
          .clone()
          .set({
            hour: startHour,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
          .add(diff, 'minutes');

        location.dayEnd = endDate.clone().set({
          hour: startHour + workHours,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
      }

      if (locationPlanType === 'automated') {
        location.current = endDate.clone();
      }

      task.planned_start = startDate.format('YYYY-MM-DD HH:mm:ss');
      task.planned_end = endDate.format('YYYY-MM-DD HH:mm:ss');
      task.due = task.planned_end;

      if (locationPlanType === 'automated') {
        if (endDate.isSame(location.dayEnd)) {
          location.current = addBusinessDays(endDate, 1, vacationDays)
            .clone()
            .set({
              hour: startHour,
              minute: 0,
              second: 0,
              millisecond: 0,
            });
          location.dayEnd = location.current.clone().set({
            hour: startHour + workHours,
            minute: 0,
            second: 0,
            millisecond: 0,
          });
        }
      }
      modulePlanned = true;
    }
    tasks.push(task);
  });

  return tasks;
}

export async function generateAutomatedPlanning(
  locations,
  startHour,
  workHours,
  startDate,
  tactsPerDay,
  modules,
  hallId,
  assemblyTask,
  assemblyTasks,
  vacationDays,
  alignedPhase
) {
  let tasks = [];

  const tactTimeInMinutes = (workHours * 60) / tactsPerDay;

  let assemblyStart = moment(startDate).clone().set({
    hour: startHour,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

  if (alignedPhase) {
    const { tasks } = await queryTasksV2(alignedPhase.license, [
      {
        column: 'type',
        operator: '=',
        values: ['dhme-module-assembly'],
      },
      {
        column: 'project',
        operator: '=',
        values: [alignedPhase.task_project.id],
      },
    ]);

    assemblyStart = moment(tasks[tasks.length - 1].planned_start)
      .clone()
      .add(tactTimeInMinutes, 'minutes');
  }

  let assemblyEnd = assemblyStart.clone().set({
    hour: startHour + workHours,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

  if (assemblyStart.isSame(assemblyEnd)) {
    assemblyStart = addBusinessDays(assemblyStart, 1, vacationDays)
      .clone()
      .set({
        hour: startHour,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
    assemblyEnd = assemblyStart.clone().set({
      hour: startHour + workHours,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
  }

  modules.forEach((m) => {
    let task = {
      title: `Assembly module '${m.module_id}'`,
      project: assemblyTask.task_project.id,
      parent: assemblyTask.id,
      sbscode: m.module_id,
      options: {
        type: 'dhme-module-assembly',
        custom_1: m.module_id,
        custom_2: hallId,
      },
    };

    let plannedTask = assemblyTasks.find(
      (t) => t?.sbscode?.code === task?.sbscode
    );

    // set id to update
    if (plannedTask) {
      task.id = plannedTask.id;
    }

    let startDate = moment(assemblyStart).clone();

    let endDate = startDate
      .clone()
      .add(locations.length * tactTimeInMinutes, 'minutes');

    while (endDate.isAfter(assemblyEnd)) {
      let diff = endDate.diff(assemblyEnd, 'minutes');

      endDate = addBusinessDays(endDate, 1, vacationDays)
        .clone()
        .set({
          hour: startHour,
          minute: 0,
          second: 0,
          millisecond: 0,
        })
        .add(diff, 'minutes');

      assemblyEnd = endDate.clone().set({
        hour: startHour + workHours,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
    }

    task.planned_start = startDate.format('YYYY-MM-DD HH:mm:ss');
    task.planned_end = endDate.format('YYYY-MM-DD HH:mm:ss');
    task.due = task.planned_end;

    assemblyStart = startDate.clone().add(tactTimeInMinutes, 'minutes');
    assemblyEnd = startDate.clone().set({
      hour: startHour + workHours,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

    if (assemblyEnd.isSame(assemblyStart)) {
      assemblyStart = addBusinessDays(assemblyStart, 1, vacationDays)
        .clone()
        .set({
          hour: startHour,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
      assemblyEnd = assemblyStart.clone().set({
        hour: startHour + workHours,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
    }
    tasks.push(task);
  });

  return tasks;
}
