import { httpClientV2 } from '@/services/httpClient';

export const fetchProjectModulesV2 = (projectId) => {
  return new Promise((resolve, reject) => {
    httpClientV2
      .get(`projects/${projectId}/modules`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};
